<template>
    <div
        class="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-image"
    >
        <div>
            <slot name="logo" />
        </div>

        <div
            class="w-full mt-6 mx-3 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg card lg:w-2/5 md:w-1/2"
        >
            <slot name="card-header" />
            <slot />
        </div>
        <slot name="footer" />
    </div>
</template>
<style lang="scss">
.bg-image {
    background-image: var(--main-background);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: right bottom;
}
.card {
    @apply rounded px-12 py-10;
    background-color: rgba(255, 255, 255, 0.9);
}
</style>
